import React from 'react';

import FadeUpOnLoad from '../components/FadeUpOnLoad';
import Layout from '../components/Layout';
import LinkButton from '../components/LinkButton';
import SEO from '../components/SEO';

const NotFound = () => {
  return (
    <Layout>
      <SEO title="404" />
      <section className="panel lead-split-panel">
        <div className="lead-split-panel__inner">
          <FadeUpOnLoad>
            <h1 className="lead-split-panel__title">404 Error</h1>
          </FadeUpOnLoad>
          <FadeUpOnLoad>
            <p>Sorry, this page cannot be found or no longer exists.</p>
          </FadeUpOnLoad>
          <LinkButton
            text="Go to the home page"
            link="/"
            theme="light"
            hasArrow={true}
          />
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;
